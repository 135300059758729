import axios from 'axios';
import { message } from 'antd';

import Cookies from 'js-cookie';
import {env} from '../app.config'
let host = env.API;

const api = axios.create({
  baseURL: host,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/vnd.github.v3+json',
    'x-api-lang': 'TR',
    'x-api-version': '0.0',
  },
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      Cookies.remove('token');
      window.localStorage.clear();
      message.error('Oturumunuz Sonlandırılmıştır ...');

      if (window.location.pathname !== '/login')
        setTimeout(() => {
          window.location.href = '/login';
        }, 500);
    }

    const data = error.response.data;
    if (data) {
      if (data.result && data.result_message.type !== 'success') {
        message.error(data.result_message.message, 2);
      }
      if (data.result && data.result_message.message !== 'error') {
        //message.success(data.result_message.message, 2);
      }
    }

    return Promise.reject(error);
  },
);

if (Cookies.get('token')) {
  api.defaults.headers.common['Authorization'] =
    'Bearer ' + Cookies.get('token');
}

api.setHeader = (key, value) => {
  // Axios headers ayarlamak için kullanılabilir
  api.defaults.headers.common[key] = value;
};
export default api;
